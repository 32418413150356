import React, { useContext } from 'react'
import BackToHpButton from 'app/component/backToHpButton/BackToHpButton'
import Layout from 'app/component/layout/LayoutView'
import { useLocalize } from 'app/base/componentHelpers'
import ImaContext from 'ima/page/context'
import PropTypes from 'prop-types'

import './ImportDocumentation.less'
import './ImportDocumentationCS.json'

const CLASSNAME = 'p-import'
const VERSION = 1

const ImportDocumentationLink = ({ url }) => {
	const { $Router: router } = useContext(ImaContext)
	const absoluteUrl = `${router.getBaseUrl()}/${url}`

	const documentUrl =
		absoluteUrl.indexOf('?') !== -1 ? `${absoluteUrl}&${VERSION}` : `${absoluteUrl}?${VERSION}`

	return (
		<a
			className={`${CLASSNAME}__link`}
			href={documentUrl}
			target='_blank'
			rel='noopener noreferrer'
		>
			{absoluteUrl}
		</a>
	)
}

ImportDocumentationLink.propTypes = {
	url: PropTypes.string
}

const ListItemImportDocumentationLink = ({ url }) => {
	return (
		<li className={`${CLASSNAME}__link-section`}>
			<ImportDocumentationLink url={url} />
		</li>
	)
}

ListItemImportDocumentationLink.propTypes = {
	url: PropTypes.string
}

const ImportDocumentation = () => {
	const localize = useLocalize()
	let links = [
		'airbag',
		'aircondition',
		'availability',
		'beds',
		'capacity',
		'color',
		'colortone',
		'colortype',
		'condition',
		'door',
		'euro',
		'firstowner',
		'fuel',
		'gearbox',
		'gearbox_auto_type',
		'gearbox_level',
		'operatingLeaseIntendedFor',
		'operatingLeaseServices',
		'seatplace',
		'servicebook',
		'state',
		'tachometrunit'
	]
	return (
		<Layout>
			<div className={CLASSNAME}>
				<BackToHpButton />
				<h1 className={`${CLASSNAME}__header-main`}>
					{localize('ImportDocumentation.dataImport')}
				</h1>
				<p className={`${CLASSNAME}__paragraphs`}>
					{localize('ImportDocumentation.descriptionServerSauto')}
				</p>
				<p className={`${CLASSNAME}__paragraphs`}>
					{localize('ImportDocumentation.descriptionXMLRPC')}
				</p>
				<p className={`${CLASSNAME}__paragraphs`}>
					{localize('ImportDocumentation.descriptionAutentizace')}
				</p>
				<p className={`${CLASSNAME}__paragraphs`}>
					{localize('ImportDocumentation.descriptionDokumentace')}
					<ImportDocumentationLink url='documents/xmlrpcImport.pdf' />
					{localize('ImportDocumentation.descriptionHodnotyArgumentu')}
				</p>
				<p className={`${CLASSNAME}__paragraphs`}>
					{localize('ImportDocumentation.descriptionCiselnikModelu')}
					<ImportDocumentationLink url='import/carList' />
					{localize('ImportDocumentation.descriptionXMLSchema')}
					<ImportDocumentationLink url='import/carListSchema.xml' />.
					{localize('ImportDocumentation.descriptionCiselnikAktualizace')}
				</p>
				<p className={`${CLASSNAME}__paragraphs`}>
					{localize('ImportDocumentation.descriptionCiselnikVybaveni')}
					<ImportDocumentationLink url='import/equipmentList' />
					{localize('ImportDocumentation.descriptionJehoSchema')}
					<ImportDocumentationLink url='import/equipmentListSchema.xml' />.
				</p>
				<p className={`${CLASSNAME}__paragraphs`}>
					{localize('ImportDocumentation.descriptionCiselnikRegionu')}
					<ImportDocumentationLink url='import/regionList' />
					{localize('ImportDocumentation.descriptionSchemaNajdete')}
					<ImportDocumentationLink url='import/regionListSchema.xml' />.
				</p>
				<p>
					{localize('ImportDocumentation.descriptionDriveValues')}
					<ImportDocumentationLink url='import/list?list=drive' />
					{localize('ImportDocumentation.descriptionJehoSchema')}
					<ImportDocumentationLink url='import/schema?schema=drive' />.
					{localize('ImportDocumentation.descriptionDestinationDrive')}
					<ImportDocumentationLink url='import/carList' />
					{localize('ImportDocumentation.drive')}
				</p>
				<h3 className={`${CLASSNAME}__header-secondary`}>
					{localize('ImportDocumentation.globalInformation')}
				</h3>
				<div className={`${CLASSNAME}__reference`}>
					<ul>
						<li className={`${CLASSNAME}__reference-headings ${CLASSNAME}__paragraphs`}>
							{localize('ImportDocumentation.codeAddresses')}
						</li>
						{links.map((link) => (
							<ListItemImportDocumentationLink key={link} url={'import/list?list=' + link} />
						))}
					</ul>
					<ul>
						<li className={`${CLASSNAME}__reference-headings ${CLASSNAME}__paragraphs`}>
							{localize('ImportDocumentation.schemaAddresses')}
						</li>
						{links.map((link) => (
							<ListItemImportDocumentationLink key={link} url={'import/schema?schema=' + link} />
						))}
					</ul>
				</div>
				<p className={`${CLASSNAME}__mailto`}>
					{localize('ImportDocumentation.contact')}
					<a className={`${CLASSNAME}__link`} href='mailto:sauto@sauto.cz'>
						sauto@sauto.cz
					</a>
				</p>
			</div>
		</Layout>
	)
}

export default ImportDocumentation
